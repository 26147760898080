// BookingForm.jsx
import { useState, useEffect, FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from "react-router-dom";
import Select from "react-select"; // Importing react-select
import * as Yup from "yup";
import {
  useSaveTourLeads,
  useCurrencyRate,
  useUpdateTourLeads,
} from "hooks/leads";
import Spinner from "components/Adways/ContentLoaders/formSpinner";
import { logEvent, getOrCreateUserId } from "../../../../src/google-analytics";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Nationalities,
  formatAmount,
  formatTripName,
  getCurrencySymbol,
} from "hooks/Global";
import TermsAndConditionsComponent from "./TermsAndConditions";
//import { usePaystackPayment } from "react-paystack";
import { PaymentSuccessModal } from "containers/LandingPages/LandingPageForm/SuccessModelPage";
import logo from "../../../images/logo.png";
import { TransactionFailed } from "../../LandingPages/LandingPageSections/SiteError";
import PaystackHook from "components/Adways/Payment";
import { setPriority } from "os";

const API_Keys = process.env.REACT_APP_PAYSTACK_API_KEY;

export interface CheckoutBookingProps {
  className?: string;
  children?: React.ReactNode;
  bookingData?: any;
}

const CheckoutBooking: FC<CheckoutBookingProps> = ({
  className = "",
  children,
  bookingData,
}) => {
  const location = useLocation();

  const CurrencySymbol = getCurrencySymbol(bookingData.currencySymbol);

  useEffect(() => {
    const userId = getOrCreateUserId();
    logEvent("User", "Opened Booking Form", userId);
  }, []);

  //Modal page state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [accepted, setAccepted] = useState<boolean>(false);
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);
  const [hasInitializePayment, setHasInitializePayment] = useState<boolean>(
    false
  );
  const [payNow, setPayNow] = useState<boolean>(false);
  const [transactionFailed, setTransactionFailed] = useState<boolean>(false);
  const handleAcceptChange = (accepted: boolean) => {
    setAccepted(accepted);
  };

  const [saveLeadsData, setSaveLeadsData] = useState([]);
  const [paystackConfig, setPaystackConfig] = useState({});
  const [updateLeadsData, setUpdateLeadsData] = useState([]);
  const [Email, setEmail] = useState<string>("");

  const { isLoading, data, submitted } = useSaveTourLeads(
    saveLeadsData,
    "https://workstation.adansitravels.com/api_save_tour_leads_4_website"
  );

  const { isLoadingUpdate, updateResponse, updated } = useUpdateTourLeads(
    updateLeadsData,
    "https://workstation.adansitravels.com/api_update_lead_with_payment"
  );

  //Get currenct rate
  const { currencyRate, isLoadingCurrencyRate } = useCurrencyRate();

  //@ts-ignore
  const todaycurrencyRate =
    (!isLoadingCurrencyRate &&
      Object.keys(currencyRate).length > 0 &&
      currencyRate?.value) ||
    [];

  //Handle Payment
  let config = {
    reference: "",
    email: Email,
    amount: 0,
    currency: "GHS",
    publicKey: API_Keys,
  };

  let specialNeed = {
    hasSpecialNeed: "false",
    needDescription: "",
  };

  function calculatePayment(bookingData) {
    const { paymentPlan, paymentPlanValue, tourData } = bookingData;
    const totalAmount = tourData.totalCost;

    // Check if paymentPlanValue is zero, return totalAmount
    if (paymentPlanValue === "0") {
      return totalAmount;
    }

    // Check if paymentPlan is "Fixed Amount" and paymentPlanValue is not zero
    if (paymentPlan === "Fix Amount" && paymentPlanValue !== "0") {
      return parseFloat(paymentPlanValue); // Return the fixed amount as a number
    }

    // Check if paymentPlan is "Percentage" and calculate the amount
    if (paymentPlan === "Percentage") {
      const percentageValue = parseFloat(paymentPlanValue);
      return (percentageValue / 100) * totalAmount; // Calculate percentage of the totalAmount
    }

    // Return totalAmount by default if none of the conditions are met
    return totalAmount;
  }

  const depositAmount = calculatePayment(bookingData);

  //Handle model after successfull login
  useEffect(() => {
    if (!hasUpdated) {
      setHasUpdated(true);
      return;
    }

    if (updated) {
      setSuccessModalText(
        `Your payment has been received, and your ${formatTripName(
          bookingData.tourData.tourName
        )} tour has been successfully booked. Our sales team will contact you shortly.`
      );
      setIsModalOpen(true);
      //console.log("email update response", updateResponse);
    }
  }, [updated]);

  const onSuccessPaystack = (reference) => {
    // Pass reference to hook after success call.

    setHasInitializePayment(false);

    setUpdateLeadsData({
      //@ts-ignore
      leadid: data.leadid,
      meta: {
        reference: reference,
      },
    });
  };

  const onClosePaystack = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //setTransactionFailed(true);
    setSuccessModalText(
      `The transaction failed, but your ${formatTripName(
        bookingData.tourData.tourName
      )} tour has been successfully booked. Our sales team will contact you shortly.`
    );
    setHasInitializePayment(false);
    setIsModalOpen(true);
    //console.log("closed");
  };

  if (submitted && payNow) {
    //@ts-ignore
    //console.log("booking reference: ", data.leadid);

    //@ts-ignore
    config.reference = String(data.leadid);
    config.amount = depositAmount * todaycurrencyRate * 100;
    //@ts-ignore
    config.email = Email;
    console.log(config);

    setPaystackConfig(config);
    setHasInitializePayment(true);
    setPayNow(false);
  }

  //Handles form search
  const NationalitySelect = ({ options, field, form }) => (
    <Select
      options={options}
      name={field.name}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      isSearchable={true}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );

  const nationalities = Nationalities();

  const nationalityOptions = nationalities.map((nationality) => ({
    value: nationality.name,
    label: nationality.name.charAt(0).toUpperCase() + nationality.name.slice(1),
  }));

  const initialValues = {
    tourId: bookingData.tourData.tourId,
    tourName: bookingData.tourData.tourName,
    specialNeed: specialNeed,
    surname: "",
    othernames: "",
    nationality: "",
    placeOfWork: "",
    phone: "",
    email: "",
    emergencyName: "",
    emergencyPhone: "",
    PaxCount: bookingData.paxCounter,
    totalCost: bookingData.tourData.totalCost,
    costing: bookingData.costing,
    source: "website",
    currentCurrencyRate: todaycurrencyRate,
  };

  const validationSchema = Yup.object().shape({
    surname: Yup.string().required("Required"),
    othernames: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    placeOfWork: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),

    email: Yup.string().required("Required").email("Invalid email address"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails must match")
      .required("Required"),

    specialNeed: Yup.object().shape({
      hasSpecialNeed: Yup.string().required("Required"),
      needDescription: Yup.string().when(
        "hasSpecialNeed",
        ([hasSpecialNeed], schema) => {
          return hasSpecialNeed === "true"
            ? schema.required("Please describe the special need")
            : schema.notRequired();
        }
      ),
    }),

    emergencyName: Yup.string().required("Required"),
    emergencyPhone: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Invalid phone number"),
  });

  if (isLoading || isLoadingUpdate) {
    return (
      <Spinner className="w-[200px] h-[100px] sm:w-[800px] sm: min-h-screen" />
    );
  }

  return (
    <div className=" mx-auto width-full bg-gray-100 p-10">
      <div className="justify-content-center mx-auto row">
        <div className="col-12 col-md-8">
          <h3 className="text-center text-xl md:text-2xl p-1 text-gray-600 mt-0 font-semibold">
            BOOKING FORM
          </h3>
          <p className="text-center p-0 text-base md:text-lg text-gray-600 font-semibold"></p>
          <div className="text-left card">
            <div className="card-body">
              <div className="alert alert-primary text-base md:text-base mt-2 text-left md:text-center p-2 text-gray-600 mb-8">
                Thank you for booking our{" "}
                {formatTripName(bookingData.tourData.tourName)} tour package!
                Please complete the form below to finish your booking.
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  let result = false;
                  try {
                    // Remove selectedStar and selectedDate if present
                    //const sanitizedValues = updateFormValues(values);
                    // console.log("sanitizedValues: ", sanitizedValues);

                    setSaveLeadsData([values]);
                    setEmail(values.email);
                    //updateEmail(sanitizedValues.email);
                    // Handle successful submission (e.g., show success message, redirect, etc.)
                  } catch (error) {
                    console.error("Form submission error: ", error);
                    // Handle submission error (e.g., show error message)
                  } finally {
                    // console.log("submited status", submitted);
                    if (submitted && !payNow) {
                      setSubmitting(false);
                      setIsModalOpen(true);
                    }
                  }
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="text-left col-span-1 md:col-span-2 text-xl font-semibold">
                      Personal Information
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Surname<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="surname"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="surname"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Other Names<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="othernames"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="othernames"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                      <p className="text-muted text-sm font-light">
                        As seen in passport
                      </p>
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Nationality<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name="nationality"
                        component={NationalitySelect}
                        options={nationalityOptions}
                        className="border-none"
                      />
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <div className="mb-1">
                        <label className="form-label font-normal text-black">
                          Phone Number<span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                          country={"gh"}
                          value={values.phone}
                          onChange={(phone) => {
                            // Parse the phone number
                            setFieldValue("phone", phone);
                          }}
                          inputStyle={{ width: "100%" }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Confirm Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="confirmEmail"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="confirmEmail"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Name
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="emergencyName"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="emergencyName"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Emergency Contact Person Phone
                        <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput
                        country={"gh"}
                        value={values.emergencyPhone}
                        onChange={(emergencyPhone) => {
                          // Parse the phone number
                          setFieldValue("emergencyPhone", emergencyPhone);
                        }}
                        inputStyle={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="emergencyPhone"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>
                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Place of Work<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        type="text"
                        name="placeOfWork"
                        className="form-control rounded-none"
                      />
                      <ErrorMessage
                        name="placeOfWork"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    <div className="mb-1 sm:ml-3">
                      <label className="form-label font-normal text-black">
                        Any Special Need?<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        as="select"
                        name="specialNeed.hasSpecialNeed"
                        className="form-control rounded-none"
                      >
                        <option value={"false"}>No</option>
                        <option value={"true"}>Yes</option>
                      </Field>
                      <ErrorMessage
                        name="specialNeed.hasSpecialNeed"
                        component="div"
                        className="text-red-600 text-sm font-light"
                      />
                    </div>

                    {values.specialNeed.hasSpecialNeed === "true" && (
                      <div className="mb-1 sm:ml-3">
                        <label className="form-label font-normal text-black">
                          Description of Special Need
                        </label>
                        <Field
                          type="text"
                          name="specialNeed.needDescription"
                          className="form-control rounded-none"
                          placeholder="Please describe your special need"
                        />
                        <ErrorMessage
                          name="specialNeed.needDescription"
                          component="div"
                          className="text-red-600 text-sm font-light"
                        />
                      </div>
                    )}

                    <div className="text-left mt-8 col-span-1 md:col-span-2 text-xl font-semibold">
                      <span>Transaction Information</span>
                      {isLoadingCurrencyRate ? (
                        <Spinner className="w-[200px] h-[100px]" />
                      ) : (
                        <div className="font-normal text-base">
                          <div className="m-3">
                            Total Cost:
                            <span className=" pl-2 font-semibold">
                              {CurrencySymbol}
                              {formatAmount(bookingData.tourData.totalCost)}
                            </span>
                          </div>
                          {bookingData.currencySymbol === "USD" ? (
                            <>
                              <div className="m-3">
                                {bookingData.currencySymbol} Rate:
                                <span className=" pl-2 font-semibold">
                                  {todaycurrencyRate}
                                </span>
                              </div>

                              <div className="m-3">
                                Initial Deposit(USD):
                                <span className=" px-2 font-semibold">
                                  {CurrencySymbol}
                                  {formatAmount(depositAmount)}
                                </span>
                              </div>
                              <div className="m-3">
                                Initial Deposit(GHS):
                                <span className=" px-2 font-semibold">
                                  ₵
                                  {formatAmount(
                                    depositAmount * currencyRate.value
                                  )}
                                </span>{" "}
                              </div>
                              <div className=" m-2 p-4 text-red-600">
                                An Initial Deposit of
                                <span className=" px-2 font-semibold">
                                  ₵
                                  {formatAmount(
                                    depositAmount * currencyRate.value
                                  )}
                                </span>
                                is required to proceed with this payment
                              </div>
                            </>
                          ) : (
                            <>
                              <div className=" m-2 p-4 text-red-600">
                                A Deposit of
                                <span className=" px-2 font-semibold">
                                  ₵{formatAmount(depositAmount)}
                                </span>
                                is required to proceed with this payment
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="col-span-1 md:col-span-2 text-center">
                      <TermsAndConditionsComponent
                        submit={submitted}
                        onSubmitted={() => {}}
                        accepted={accepted}
                        onAccepted={handleAcceptChange}
                      />
                    </div>
                    <div className="col-span-1 md:col-span-2 text-center mt-4">
                      <div className="flex mx-20 pb-10 justify-between space-x-10">
                        <button
                          type="submit"
                          disabled={!accepted}
                          className={`mt-4 px-4 py-2 rounded ${
                            accepted
                              ? "bg-green-600 text-white hover:bg-green-700"
                              : "bg-gray-300 text-gray-500 cursor-not-allowed"
                          }`}
                          onClick={() => {
                            setPayNow(true);
                          }}
                        >
                          Pay Now
                        </button>

                        <button
                          type="submit"
                          className={`mt-4 px-4 py-2 rounded ${
                            accepted
                              ? "bg-yellow-600 text-white hover:bg-yellow-700"
                              : "bg-gray-300 text-gray-500 cursor-not-allowed"
                          }`}
                          disabled={!accepted}
                          onClick={() => {
                            setPayNow(false);
                          }}
                        >
                          Pay Later
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <PaymentSuccessModal
        isOpen={isModalOpen}
        fbPixelTriggered={false}
        onClose={() => setIsModalOpen(false)}
        onfbPixelTriggered={() => {}}
        msgText={successModalText}
      />
      {hasInitializePayment && (
        <PaystackHook
          config={paystackConfig}
          onClose={onClosePaystack}
          onSuccess={onSuccessPaystack}
        />
      )}
      {
        <TransactionFailed
          errorMessage=""
          details="This transaction was cancelled by the user. Please try again later. "
          buttonText="Try Again"
          isOpen={transactionFailed}
          onClose={() => setTransactionFailed(false)}
        />
      }
    </div>
  );
};

export default CheckoutBooking;
