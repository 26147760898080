import React, { ChangeEvent, FormEvent } from "react";

interface TermsAndConditionsComponentProps {
  submit: boolean;
  onSubmitted: () => void;
  accepted: boolean;
  onAccepted: (accepted: boolean) => void;
}

const TermsAndConditionsComponent: React.FC<TermsAndConditionsComponentProps> = ({
  submit,
  onSubmitted,
  accepted,
  onAccepted,
}) => {
  const handleAcceptChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Directly toggle the accepted state based on the checkbox state
    onAccepted(e.target.checked);
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    if (accepted) {
      onSubmitted();
    }
  };

  return (
    <div className="p-6 w-full max-w-4xl mx-auto">
      <h1 className="text-xl text-left text-gray-600 font-bold mb-4">
        Terms and Conditions
      </h1>
      <div
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Times New Roman", serif',
          textAlign: "justify",
          lineHeight: "150%",
        }}
      >
        <div className="overflow-y-scroll h-60 border-gray-300 p-2 rounded-lg bg-white">
          <p>
            <strong>
              <u>
                <span
                  style={{ fontFamily: '"Cambria", serif', color: "black" }}
                >
                  INTRODUCTION
                </span>
              </u>
            </strong>
          </p>

          <p>
            <span style={{ fontFamily: '"Cambria", serif', color: "black" }}>
              The Client’s contractual agreement with Adansi Travels Ltd
              (referred to as the “Company”) is established on the following
              terms and conditions. Please review these conditions as they
              outline the rights and responsibilities of both parties.
            </span>
          </p>

          <p>
            <span style={{ fontFamily: '"Cambria", serif', color: "black" }}>
              By acknowledging and accepting these terms and conditions, the
              Client affirms that they have either personally read them or
              ensured that they have been read, explained, and interpreted to
              them in a language they comprehend.
            </span>
          </p>

          <p>
            <span style={{ fontFamily: '"Cambria", serif', color: "black" }}>
              By selecting the "I Accept" box, the Client affirms their
              understanding and agreement to the specified terms and conditions.
            </span>
          </p>

          <p>
            <span style={{ fontFamily: '"Cambria", serif', color: "black" }}>
              These terms and conditions apply to all travel arrangements
              facilitated by the Company, and they take precedence over any
              prior agreements between the Client and representatives of the
              Company.
            </span>
          </p>

          <p>
            <span style={{ fontFamily: '"Cambria", serif', color: "black" }}>
              By consenting to these terms, the Client agrees to be bound by the
              terms and conditions stipulated below.
            </span>
          </p>

          <ol>
            <li>
              <strong>
                <u>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    DEFINITIONS
                  </span>
                </u>
              </strong>
              <ol>
                <li>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    “<strong>Child”</strong> means a person between the ages of
                    2 years and 11 years for the purposes of the trip. Children
                    12 years of age and above are considered adults by most
                    hotels and tour sites and or attraction centers and by
                    airlines.
                  </span>
                </li>
                <li>
                  <strong>
                    <span
                      style={{ fontFamily: '"Cambria", serif', color: "black" }}
                    >
                      “Infant”
                    </span>
                  </strong>
                  <span
                    style={{ fontFamily: '"Cambria", serif', color: "black" }}
                  >
                    {" "}
                    means a person who is less than 2 years at the time of
                    travel
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    “<strong>Client”</strong> means a person who has signed up
                    for and paid for a trip with the Company.
                  </span>
                </li>
                <li>
                  <span
                    style={{ fontFamily: '"Cambria", serif', color: "black" }}
                  >
                    “<strong>Force Majeure</strong>” means an event that could
                    not have reasonably been anticipated and planned for, and
                    which is beyond the control of, and occurs without the fault
                    or negligence of, the party failing to perform.
                  </span>
                </li>
                <li>
                  <strong>
                    <span
                      style={{ fontFamily: '"Cambria", serif', color: "black" }}
                    >
                      “Group Hotel”
                    </span>
                  </strong>
                  <span
                    style={{ fontFamily: '"Cambria", serif', color: "black" }}
                  >
                    {" "}
                    means the accommodation provided for Clients.
                  </span>
                </li>
                <li>
                  <strong>
                    <span
                      style={{ fontFamily: '"Cambria", serif', color: "black" }}
                    >
                      “Host Country”
                    </span>
                  </strong>
                  <span
                    style={{ fontFamily: '"Cambria", serif', color: "black" }}
                  >
                    {" "}
                    means the country the Company shall visit and tour with its
                    Clients.
                  </span>
                </li>
                {/* Add more list items as necessary */}
              </ol>
            </li>
          </ol>

          <ol>
            <li>
              <strong>
                <u>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    TOUR PACKAGE
                  </span>
                </u>
              </strong>
              <ol>
                <li>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    Company promotes a range of Tour Packages, each featuring
                    distinctive prices, itineraries, and dates among others.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    The Client has the freedom to choose packages that align
                    with their preferences or can request the Company to tailor
                    a package specifically for them.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Cambria", serif' }}>
                    The Tour Packages generally cover the Client’s daily
                    breakfast while on Tour. Clients are responsible for
                    arranging their lunch and dinner unless explicitly specified
                    otherwise in the travel itinerary. The Company may suggest
                    dining options during the tour, but it does not
                  </span>
                  <span style={{ fontFamily: '"Cambria",serif' }}>
                    assure the safety and quality of the food offered at those
                    places. Clients are advised to adequately budget for their
                    meals and related expenses throughout the trip.
                  </span>
                </li>
                <li className="text-blue-600 py-4 font-semibold">
                  <a
                    href="http://adansitravels.com/terms-and-conditions"
                    className="hover:text-blue-800 hover:underline"
                  >
                    Read More
                  </a>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="mt-6">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={accepted}
            onChange={handleAcceptChange}
            className="form-checkbox h-5 w-5 text-green-600"
          />
          <span>I Accept the Terms and Conditions</span>
        </label>
      </form>

      {submit && !accepted && (
        <p className="text-red-500 mt-4">
          You must accept the terms and conditions to proceed.
        </p>
      )}
    </div>
  );
};

export default TermsAndConditionsComponent;
