import React, { FC, useState, useEffect } from "react";
import TabFilters from "./ToursTabFilters";
import TrendingTours from "../../Adways/TrendingTours";
import { useFetchAllTours } from "hooks/tours";
import LoadingListing from "../../Adways/ContentLoaders/toursListingLoader";
import Heading2 from "../../../shared/Heading/AdansiHeading";
import { useSelector } from 'react-redux';
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ShowErr from "../../Adways/lottieFiles/nodata";

// Define the correct data type for your tour data
interface TourDataType {
  id: string;
  className?: string;
  checkIn: {
    seconds: number;
    nanoseconds: number;
  };
  destination: string; // Assuming destination property exists in your data
  // Add properties according to your tour data structure
}

const SectionGridFilterCard: FC = () => {
  const { data: toursData, isLoading } = useFetchAllTours();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [checkedItemsDes, setCheckedItemsDes] = useState<string[]>([]);
  const [filteredTours, setFilteredTours] = useState<TourDataType[]>([]);
 // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDate = useSelector((state) => state.tourFilter.selected_dates); // Access state
   // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  const filterDestination = useSelector((state) => state.tourFilter.selected_destination); // Access state
  const [homePageFilteredMonth, setHomePageFilteredMonth] = useState<string | null>(null);
  const [homePageFilteredDestination, setHomePageFilteredDestination] = useState<string | null>(null);

  // Function to get selected month from checkedItems
  const getSelectedMonth = () => {
    const months: string[] = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ];
    for (let i = 0; i < months.length; i++) {
      if (checkedItems.includes(months[i])) {
        return i; // Return the month index
      }
    }
    return null; // Return null if no month is found
  };



  useEffect(() => {
    if (filterDate) {
      const homePageFilteredMonthData = getMonthFromStartDate(filterDate);
      setHomePageFilteredMonth(homePageFilteredMonthData);
    }
    if (filterDestination) {
      setHomePageFilteredDestination(filterDestination);
    }
  }, [filterDate, filterDestination]);


  useEffect(() => {
    if (toursData && !isLoading) {
      let filteredByItems = toursData;
      let filteredByDestinations = toursData;

      // Default filter by homePageFilteredDestination
      if (homePageFilteredDestination) {
        filteredByDestinations = toursData.filter((tour) =>
          tour.destination.includes(homePageFilteredDestination)
        );
      }

      // Default filter by homePageFilteredMonth
      if (homePageFilteredMonth !== null) {
        filteredByDestinations = filteredByDestinations.filter((tour) => {
          const checkInDate = new Date(tour.checkIn.seconds * 1000);
           // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
          return checkInDate.getMonth() === homePageFilteredMonth;
        });
      }

      // Filter tours based on checked items if checks are not empty
      if (checkedItems.length > 0) {
        filteredByItems = toursData.filter((tour) =>
          checkedItems.includes(tour.someProperty)
        );
      }

      // Filter tours based on checked destinations if checks are not empty
      if (checkedItemsDes.length > 0) {
        filteredByDestinations = filteredByDestinations.filter((tour) =>
          checkedItemsDes.includes(tour.destination)
        );
      }

      // Get the selected month from checkedItems
      const selectedMonth = getSelectedMonth();

      // Filter tours based on selected month
      if (selectedMonth !== null) {
        filteredByDestinations = filteredByDestinations.filter((tour) => {
          const checkInDate = new Date(tour.checkIn.seconds * 1000);
          return checkInDate.getMonth() === selectedMonth;
        });
      }

      // Combine the two filters
      const finalFilteredTours = filteredByItems.filter((tour) =>
        filteredByDestinations.includes(tour)
      );
      //console.log(finalFilteredTours , "filtered tours")


      // Set the filtered tours to state
      setFilteredTours(finalFilteredTours);

      // Clear homePageFilteredMonth and homePageFilteredDestination after filtering
      setHomePageFilteredMonth(null);
      setHomePageFilteredDestination(null);
    }
  }, [toursData, isLoading, checkedItems, checkedItemsDes, homePageFilteredMonth, homePageFilteredDestination]);

  const getMonthFromStartDate = (dateObject) => {
    const startDate = new Date(dateObject.start_date);
    const month = startDate.toLocaleString('default', { month: 'long' });
    return month;
  };

  if (isLoading) {
    return <div className={`nc-SectionGridFilterCard mb-5 mt-5 pt-10 pb-10`}><LoadingListing /></div>;
  }



  return (  
<div className={`nc-SectionGridFilterCard mb-5 mt-5 pt-10 pb-10`}>
  {/* BackgroundSection with temporary styles to ensure it renders */}
  <div className="absolute inset-0 z-0 bg-gray-200">
    <BackgroundSection />
  </div>
  
  <div className="relative z-10">
    <Heading2
      heading="Tour Packages"
      subHeading={
        <span className="block text-neutral-500 dark:text-neutral-400">
          Found {toursData.length} tours
          <span className="mx-2">·</span>
          {/* May 12 - Dec 18 */}
        </span>
      }
    />
    <TabFilters
      checkedItems={checkedItems}
      setCheckedItems={setCheckedItems}
      checkedItemsDes={checkedItemsDes}
      setCheckedItemsDes={setCheckedItemsDes}
    />
    <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 mt-5">
      
      {filteredTours.map((tour) => (
         // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
        <TrendingTours key={tour.id} data={tour} />
      ))}
    </div>

    {filteredTours.length == 0 && <ShowErr/>}
  </div>
</div>

  );
};

export default SectionGridFilterCard;

