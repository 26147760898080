import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Quick Links",
    menus: [
      { href: "/about-us", label: "About Us" },
      { href: "/about-us#services", label: "Our Services" },
      { href: "/contacts", label: "Contact Us" },
      { href: "/login", label: "Login" },
      { href: "/feedback", label: "feedback" },
    ],
  },
  {
    id: "1",
    title: "Subsidiary",
    menus: [
      { href: "https://thebosstravelclub.com/", label: "The Boss Travel Club" },
      {
        href: "https://adansihealthtourism.com/",
        label: "Adansi Health Tourism",
      },
      { href: "https://adansiproperties.com/", label: "Adansi Properties" },
      { href: "https://adansieduconsult.com/", label: "Adansi Educonsult" },
      { href: "http://seeghanadmc.com/", label: "See Ghana DMC" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      {
        href:
          "https://workstation.adansitravels.com/customer_auth?p=refund_portal",
        label: "Refund",
      },
      {
        href: "https://lp.adansitravels.com/travel-now-pay-later/",
        label: "Travel now pay later",
      },
      {
        href: "https://insurance.thebosstravelclub.com/",
        label: "General Insurance",
      },
      { href: "#", label: "Chat with us" },
      { href: "#", label: "Call Center" },
    ],
  },
];

const Footer: React.FC = () => {
  const location = useLocation();
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    //Hide all links for Landing page
    if (location.pathname.includes("/ads")) {
      return null;
    }

    //Handles footer for Links for websiter footer
    return (
      <div key={index} className="text-sm">
        <h2
          className="font-semibold text-neutral-700 dark:text-neutral-200"
          style={{ color: "white" }}
        >
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                style={{ color: "#dcdcdc" }}
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  //Handle footer for landing pages
  if (location.pathname.includes("/ads")) {
    return (
      <div
      className="nc-Footer relative py-8 md:py-24 lg:py-10 border-t border-neutral-200 dark:border-neutral-700"
      style={{ backgroundColor: "#03844e", borderTop: "solid 2px #f99f1b" }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-white flex-wrap md:flex-row md:justify-between">
          
          {/* First column - Contact details */}
          <div className="flex flex-col items-center text-center sm:items-start sm:text-left sm:m-8">
            <div className="flex flex-col items-center sm:items-start p-4">
              {/* Phone Icon and Numbers */}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 122.27"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z" />
                </svg>
                <div className="flex flex-col text-center sm:text-left ml-4">
                  <p>+233 55 264 8267</p>
                  <p>+233 59 550 0817</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center sm:items-start p-4">
              {/* Email Icon and Address */}
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                  fill="currentColor"
                  width="24px"
                  height="24px"
                >
                  <path d="M896 192H128c-35.2 0-64 28.8-64 64v512c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V256c0-35.2-28.8-64-64-64zm0 64L512 512 128 256h768zm-768 512V288l384 288 384-288v480H128z" />
                </svg>
                <div className="flex flex-col text-center sm:text-left mb-6 ml-4">
                  <p>sales@adansitravels.com</p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Second column - Social icons */}
          <div className="flex justify-center sm:justify-start sm:m-8 text-center text-[25px] space-x-4">
            <SocialsList1 className="flex items-center justify-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
    
          {/* Third column - Powered by text */}
          <div className="flex flex-col items-center sm:items-start text-center sm:text-left m-8">
            <div className="flex items-center p-4">
              
              <div className="flex flex-col text-center sm:text-left ml-4">
                <p>136, La Bawaleshie Road, </p>
                <p>East Legon American House, Accra Ghana</p>
                <p className="pt-4">Powered by Adansi Travels</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    );
  }

  //Handles footer for Website
  return (
    <div
      className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700"
      style={{ backgroundColor: "#03844e", borderTop: "solid 2px #f99f1b" }}
    >
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">{/* <Logo /> */}</div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div
        className="flex justify-center mt-4"
        style={{ marginTop: "50px", color: "white" }}
      >
        Powered by Adansi Travels
      </div>
    </div>
  );
};

export default Footer;
