import React, { FC, ReactNode, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
// import StayCard from "components/StayCard/StayCard";
import StayCard from "components/Adways/TourCard2";
import TourListingLoader from "../../components/Adways/ContentLoaders/toursListingLoader";

import TrendingTours from "../../components/Adways/TrendingTours";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string; 
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  tourData: StayDataType[];
  tabActive: string;
  
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  tourData,
  tabActive,
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Tour Packages",
  subHeading = "Curated experiences tailored to suit every travel vibe",
  headingIsCenter,
  tabs,
}) => {

  const [activeTab, setActiveTab] = useState(tabActive);

  const handleTabClick = (item: string) => {
    setActiveTab(item); // Update the tabActive state with the selected tab
  };

  const filteredData = tourData.filter((tour) =>
    activeTab && tour?.name && tour?.destination.toLowerCase().includes(activeTab.toLowerCase())
  );


  // const renderCard = (stay: StayDataType) => {
  //   return <StayCard key={stay.id} data={stay} />;
  // };

  const renderCard = (stay: StayDataType) => {
      // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
   return  <TrendingTours  data={stay}/>
    // return <StayCard key={stay.id} data={stay} />
  };


  return (
    <div id="#trendingTours" className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={tabActive}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={handleTabClick} 
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 ${gridClass}`}
      >
        {
          filteredData.map((tour) => renderCard(tour))
        }
      </div>
 
    </div>
  );
  
};

export default SectionGridFeaturePlaces;
