import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "../../../shared/Checkbox/Checkbox";
import ButtonPrimary from "../../Adways/AdansiBtn";
import ButtonThird from "../../../shared/Button/ButtonThird";
import ButtonClose from "../../../shared/Button/ButtonCircle";
import { useFetchTourDestinations } from "hooks/tours";
import DestinationLoader from "../../Adways/ContentLoaders/TourDestinationLoader";

const transformData = (dataArray) => {
  return dataArray.map((item) => ({
    name: item.name,
    description: "",
    checked: true,
  }));
};

const TabFilters = ({
  checkedItems,
  setCheckedItems,
  checkedItemsDes,
  setCheckedItemsDes,
}) => {
  const { data: toursData, isLoading } = useFetchTourDestinations();
  const moreFilter1 = transformData(toursData);

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreDateFilter, setisOpenMoreDateFilter] = useState(false);

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const closeModalMoreDateFilter = () => setisOpenMoreDateFilter(false);
  const openModalMoreDateFilter = () => setisOpenMoreDateFilter(true);

  const handleCheckboxChange4Month = (name: string) => (checked: boolean) => {
    setCheckedItems((prevCheckedItems) => {
      if (checked) {
        return [...prevCheckedItems, name];
      } else {
        return prevCheckedItems.filter((item) => item !== name);
      }
    });
  };

  const handleCheckboxChange4Des = (name: string) => (checked: boolean) => {
    setCheckedItemsDes((prevCheckedItems) => {
      if (checked) {
        return [...prevCheckedItems, name];
      } else {
        return prevCheckedItems.filter((item) => item !== name);
      }
    });
  };


const renderTabsRoomAndBeds = () => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-600 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Filter with Month</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative grid grid-cols-2 gap-4 px-5 py-6">
                    {months.map((month) => (
                      <div key={month}>
                        <Checkbox
                          name={month}
                          label={month}
                          subLabel={""}
                          checked={checkedItems.includes(month)}
                          onChange={handleCheckboxChange4Month(month)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

const renderTabsTypeOfPlace = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-600 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
            >
              <span>Filter with Destination</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-0 mx-auto sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl mx-auto ml-5 shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative grid grid-cols-2 gap-4 px-5 py-6">
                    {moreFilter1.map((item) => (
                      <div key={item.name}>
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          onChange={handleCheckboxChange4Des(item.name)}
                          checked={checkedItemsDes.includes(item.name)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  


  const renderMoreFilterItem = (data) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);

    return (
      <div className="grid md:grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              checked={checkedItemsDes.includes(item.name)}
              onChange={handleCheckboxChange4Des(item.name)}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              checked={checkedItemsDes.includes(item.name)}
              onChange={handleCheckboxChange4Des(item.name)}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderTabMobileFilter = () => {
    return (
      <div>
        <div
          className={`flex hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-[#03844e] bg-[#e6f7ec] text-[#03844e] focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>filters by Destination</span>
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden"
                aria-hidden="true"
                onClick={closeModalMoreFilter}
              >
                &#x2715;
              </span>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Filter with Destination
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-2">
                      {moreFilter1 && !isLoading ? (
                        renderMoreFilterItem(moreFilter1)
                      ) : (
                        <DestinationLoader />
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <ButtonClose onClick={closeModalMoreFilter} />
                    <ButtonThird onClick={closeModalMoreFilter}>Clear</ButtonThird>
                    <ButtonPrimary onClick={closeModalMoreFilter}>Apply</ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="w-full" style={{ marginTop : "-10%"}}>
  <div className="grid grid-cols-1 gap-6">
    <div className="mt-6">
    <div className="flex justify-end space-x-6">
        {renderTabsRoomAndBeds()}
        {renderTabsTypeOfPlace()}
      </div>
    </div>

    {/* <div className="mt-6">
      {renderTabMobileFilter()}
    </div> */}
  </div>
</div>

  );
};

export default TabFilters;

