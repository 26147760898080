import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop"; 
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import all_tours from "../components/Adways/tours/page";
import visa_page from "../components/Adways/visa/page";
import about_page from "../components/Adways/about/page";
import contacts_page from "../components/Adways/contact-us/page";
import lp from "../containers/LandingPages/themer";
import BookingForm from "containers/LandingPages/LandingPageForm/BookingForm";
import FeedbackForm from "../components/Adways/Feedbacks";
import TermsAndConditionsComponent from "containers/ListingDetailPage/listing-stay-detail/TermsAndConditions";



export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome, showHeader: true },
  { path: "/#", exact: true, component: PageHome, showHeader: true },
     // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  { path: "/tour-detail/:id", component: ListingStayDetailPage, showHeader: true },
     // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  { path: "/ads/:theme/:tour", component: lp, showHeader: false },
  { path: "/ads/booking_form", component: BookingForm, showHeader: false },
  { path: "/terms-and-conditions", component: TermsAndConditionsComponent, showHeader: true },
  { path: "/tours", component: all_tours, showHeader: true },
  { path: "/visa", component: visa_page, showHeader: true },
     // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  { path: "/about-us", component: about_page, showHeader: true },
     // Use //@ts-ignore to ignore TypeScript error
  //@ts-ignore
  { path: "/contacts", component: contacts_page, showHeader: true },
  { path: "/home-1-header-2", exact: true, component: PageHome, showHeader: true },
  { path: "/listing-experiences-detail", component: ListingExperiencesDetailPage, showHeader: true },
  { path: "/account", component: AccountPage, showHeader: true },
  { path: "/account-password", component: AccountPass, showHeader: true },
  { path: "/account-savelists", component: AccountSavelists, showHeader: true },
  { path: "/account-billing", component: AccountBilling, showHeader: true },
  { path: "/login", component: PageLogin, showHeader: true },
    //@ts-ignore
  { path: "/feedback", component: FeedbackForm, showHeader: true },

];

const MyRoutes = () => {
  const location = useLocation();
  const WIN_WIDTH = useWindowSize().width || (typeof window !== "undefined" ? window.innerWidth : undefined);

  const shouldShowHeader = pages.some(({ path, showHeader }) => {
    const matchExact = path === location.pathname;
    const matchPath = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`).test(location.pathname);
    return (matchExact || matchPath) && showHeader;
  });

  return (
    <>
      <ScrollToTop />
      {shouldShowHeader ?  <SiteHeader />  : <SiteHeader defaultHeader={"lp"}/>}

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {WIN_WIDTH && WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

const App = () => (
  <BrowserRouter>
    <MyRoutes />
  </BrowserRouter>
);

export default App;
