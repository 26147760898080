import { FC, useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";
// import Image from "next/image";

import banner1 from "../../../images/slider-img/egypt-cruise.jpg";
import banner2 from "../../../images/slider-img/garden-by-the-bay.jpg";
import banner3 from "../../../images/cape-town1.jpg";
import indiana from "../../../images/slider-img/indiana-uni.jpg"
import dubai from "../../../images/slider-img/dubai.jpg"
import kofi_kinaata from "../../../images/slider-img/kofi-kinaata.jpg"
import ykofi_kinaata from "../../../images/slider-img/y-kofi-kinaata.jpg"
import award from "../../../images/slider-img/awards-.jpg"



const DEMO_CATS = [
  {
    id: "6",
    thumbnail: award,
    width: 1200,
    height: 450,
  },
  {
    id: "5",
    thumbnail: ykofi_kinaata,
    width: 1200,
    height: 450,
  },
  {
    id: "5",
    thumbnail: dubai,
    width: 1200,
    height: 450,
  },
  {
    id: "1",
    thumbnail: banner1,
    width: 1200, // Specify the actual width of the image
    height: 450, // Reduce the height of the image to 450px
  },
  {
    id: "2",
    thumbnail: indiana,
    width: 1200, // Specify the actual width of the image
    height: 450, // Reduce the height of the image to 450px
  },
  {
    id: "3",
    thumbnail: banner2,
    width: 1200,
    height: 450,
  },
  // {
  //   id: "4",
  //   thumbnail: banner3,
  //   width: 1200,
  //   height: 450,
  // },


];

const SectionSliderLandingPageBanner: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex < DEMO_CATS.length - 1 ? prevIndex + 1 : 0
      );
    }, 6000); // Adjust the interval time to 5000 milliseconds (5 seconds)

    return () => clearInterval(interval);
  }, []);

  const windowWidth = typeof window !== "undefined" ? window.innerWidth : 1024;
  useEffect(() => {
    if (windowWidth < 320) {
      setNumberOfItems(1);
    } else if (windowWidth < 500) {
      setNumberOfItems(1);
    } else if (windowWidth < 1024) {
      setNumberOfItems(1);
    } else {
      setNumberOfItems(1);
    }
  }, [windowWidth]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < DEMO_CATS.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    trackMouse: true,
  });

  return (
    <div className="relative w-full overflow-hidden mb-0" {...handlers}>
      <MotionConfig
        transition={{
          x: { type: "spring", stiffness: 100, damping: 20 }, // Adjust the stiffness and damping values for slower transition
          opacity: { duration: 0.5 }, // Adjust the opacity transition duration
        }}
      >
        <div className="relative overflow-hidden">
          <motion.ul
            initial={false}
            className="relative whitespace-nowrap -mx-2"
          >
            <AnimatePresence initial={false}>
              {DEMO_CATS.map((item, indx) => (
                <motion.li
                  className="inline-block px-2"
                  initial={{
                    x: `${(currentIndex - 1) * -100}%`,
                  }}
                  animate={{
                    x: `${currentIndex * -100}%`,
                  }}
                  exit={{
                    x: `${(currentIndex + 1) * -100}%`,
                  }}
                  key={indx}
                  style={{
                    width: `calc(100% / ${numberOfItems})`,
                  }}
                >
                  <img
                    src={item.thumbnail}
                    alt=""
                    className="object-cover w-full h-[250px] sm:h-[500px]"
                    style={{  }}
                  />
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        </div>
      </MotionConfig>
      {currentIndex ? (
        <button
          onClick={() => setCurrentIndex(currentIndex - 1)}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        >
          &#10094;
        </button>
      ) : null}

      {DEMO_CATS.length > currentIndex + 1 ? (
        <button
          onClick={() => setCurrentIndex(currentIndex + 1)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        >
          &#10095;
        </button>
      ) : null}
    </div>
  );
};

export default SectionSliderLandingPageBanner;

