import React, { useState, useEffect } from "react";
import {
  useFetchAllTrendingTours,
  useFetchHomeActiveToursByDestination,
} from "../../hooks/tours";
import TrendingTours from "../../components/Adways/TrendingTours";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import AdwayHero from "../../components/Adways/adansiHero";
import Heading2 from "../../shared/Heading/AdansiHeading";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import HomepageLoader from "../../components/Adways/ContentLoaders/2ColumnLoader";
import AdansiVideo from "../../components/Adways/AdWayVideo";
import NewAds from "../../components/Adways/NewAdsBanner";

import anniAds from "../../images/ads/web_annibanner.jpg";
import web_visa_ads from "../../images/ads/web_ads_srv_visa.png";
import web_flight_ads from "../../images/ads/web_ads_srv_ticketing.png";
import web_hotels_ads from "../../images/ads/web_ads_srv_hotel.png";
import web_holiday_ads from "../../images/ads/web_ads_srv_holiday.png";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from "../../images/logo.png";



import AnniversaryCountdown from "./AnniversaryAds";
import { initGA, logPageView, getOrCreateUserId, logEvent } from "../../../src/google-analytics";


const PageHome = () => {

  useEffect(() => {
    initGA();
    const userId = getOrCreateUserId();
    logPageView();
    logEvent('User', 'Visited HomePage', userId);
  }, []);

  const popular_destinations = [
    // "11TH ANNIVERSARY",
    "DUBAI",
    "AFRICA",
    "GHANA",
    "EUROPE",
    "ASIA",
    
    
    //"SINGAPORE - DUBAI",
  ];
  const {
    data: fetchedToursData,
    isLoading: isLoadingToursData,
  } = useFetchAllTrendingTours();
  const {
    data: toursByDestination,
    isLoading: isLoadingDestination,
  } = useFetchHomeActiveToursByDestination(popular_destinations);


  const banners = [
    { image: anniAds },
    { image: web_visa_ads },
    { image: web_flight_ads },
    { image: web_holiday_ads },
    { image: web_hotels_ads },
  ];

  // console.log(fetchedToursData)
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHISM */}
      <BgGlassmorphism />

      <div id="AdansiHeroSections">
        <AdwayHero />
        <div
          className="mb-5 md:md-0"
          style={{ marginTop: "-10%"}}
        >
          <HeroSearchForm />
        </div>
      </div>

      <div
        className="container hidden sm:block flex items-center justify-center -mt-12 sm:-mt-0"
        style={{ marginTop: "5%" }}
      >
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3">
            <span
              className="las la-plane text-5xl sm:text-4xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-xl sm:text-lg">Let's tour the World</span>
              <p className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400">
                Dive into our tailored experiences, designed to capture the
                essence of life's beauty.
              </p>
            </div>
          </div>
          <div className="border-r border-neutral-300 dark:border-neutral-600 h-12"></div>{" "}
          {/* Divider */}
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3">
            <span
              className="las la-trophy text-5xl sm:text-4xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-xl sm:text-lg bold">
                Award Winning Services
              </span>
              <p className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400">
                Exceptional experiences await with our acclaimed services.
              </p>
            </div>
          </div>
          <div className="border-r border-neutral-300 dark:border-neutral-600 h-12"></div>{" "}
          {/* Divider */}
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3">
            <span
              className="las la-users text-5xl sm:text-4xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-xl sm:text-lg">Customer Support</span>
              <p className="hidden sm:block text-sm text-neutral-500 dark:text-neutral-400">
                Get round-the-clock support from our AI chatbot and call center
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container flex items-center justify-center sm:hidden"
        style={{ marginTop: "-5%" }}
      >
        <div className="grid grid-cols-2 gap-4 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col items-center text-center space-y-2">
            <span
              className="las la-plane text-3xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-base font-bold">Let's tour the World</span>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                Dive into our tailored experiences, designed to capture the
                essence of life's beauty.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center text-center space-y-2">
            <span
              className="las la-trophy text-3xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-base font-bold">
                Award Winning Services
              </span>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                Exceptional experiences await with our acclaimed services.
              </p>
            </div>
          </div>
          <div className="col-span-2 flex flex-col items-center text-center space-y-2">
            <span
              className="las la-users text-3xl"
              style={{ color: "#03844e" }}
            ></span>
            <div>
              <span className="text-base font-bold">Customer Support</span>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                Get round-the-clock support from our AI chatbot and call center.
              </p>
            </div>
          </div>
        </div>
      </div>

      {isLoadingToursData ? (
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          <HomepageLoader />
        </div>
      ) : (
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          <div
            className="relative nc-SectionGridFilterCard mb-5 mt-5 pt-10 pb-10"
            style={window.innerWidth <= 768 ? { marginTop: "10px" } : {}}
          >
            <Heading2
              heading="Trending Tours"
              subHeading={
                <span className="block text-neutral-500 dark:text-neutral-400 mt-1">
                  Recommended for Your Perfect Experience
                </span>
              }
            />

            <div className="grid grid-cols-1 gap-4 md:gap-8 xl:grid-cols-2">
              {fetchedToursData.length > 0 &&
                fetchedToursData.map((tour) => <TrendingTours data={tour} />)}
            </div>
          </div>

          <div style={{ marginTop: "5%" }}>
            <NewAds banners={banners} />
          </div>

          <div id="tour-packages" className="relative py-16">
            <BackgroundSection />
            <SectionGridFeaturePlaces
              tourData={toursByDestination}
              tabs={popular_destinations}
              tabActive={popular_destinations[0]}
            />
          </div>

          <AdansiVideo />

          {/* <div style={{ marginTop: "10%" }}><NewAds banners={banners} /></div> */}
        </div>
      )}

      {/* <div className="w-1/5">
        <AnniversaryCountdown
        />
      </div> */}
       
      <FloatingWhatsApp 
      phoneNumber={"233531011617"}  
      accountName={"Adansi Travels"}
      avatar={logo}
      statusMessage={"Feel life's Beauty"}
      chatMessage={"Welcome to Adansi Travels, How may we help?"}/>
    </div>
  );
};

export default PageHome;
